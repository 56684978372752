body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@keyframes slideDown {
  0% {
    transform: translateY(-150%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bumpUp {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }

  70% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}

.sidenav .menu-items:nth-child(1) {
  animation: slideDown 0.6s ease-out;
  animation-delay: 0s;
}

.sidenav .menu-items:nth-child(2) {
  animation: slideDown 0.6s ease-out, bumpUp 0.4s ease-out;
  animation-delay: 0.2s, 0.2s;
}

.sidenav .menu-items:nth-child(3) {
  animation: slideDown 0.6s ease-out, bumpUp 0.4s ease-out;
  animation-delay: 0.4s, 0.4s;
}

.sidenav .menu-items:nth-child(4) {
  animation: slideDown 0.6s ease-out, bounce 0.6s ease-out;
  animation-delay: 0.6s, 0.6s;
}

header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  margin: 0 auto;
  box-sizing: border-box;
}

.sidenav {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 0;
  background-color: #070707;
  border-right: 1px solid rgb(97, 97, 97);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  transition: 0.5s;
  z-index: 10;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  color: #f1f1f1;
  background: transparent;
  border: none;
}

.sidenav-inner-container {
  margin: 25% 0;
  width: 100%;
  height: auto;
  margin-left: 20px;
}

.sidenav a {
  text-decoration: none;
  color: white;
}

.menu-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10%;
  transition: all 0.3s ease;
}

.menu-items:active,
.menu-items:hover {
  background: linear-gradient(135deg, #ac343e, #660e29, #d64418);
}

.menu-items img {
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.menu-items h3 {
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  text-align: left;
  margin-left: 25%;
  margin-top: 5%;
  align-self: center;
  flex-grow: 1;
}

.menu-home,
.menu-contact,
.menu-experience,
.menu-portfolio,
.menu-about,
.menu-linkedin,
.menu-github {
  width: 20px;
  min-width: 20px;
  height: auto;
}

.menu-icon {
  position: absolute;
  left: 2%;
  top: 20%;
  width: 35px;
  height: auto;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.menu-icon:hover {
  background: linear-gradient(135deg, #ac343e, #660e29, #d64418);
  border: 1px solid #660e29;
}

.menu-socialmedia {
  position: absolute;
  bottom: 22%;
}

.menu-socialmedia1 {
  position: absolute;
  bottom: 12%;
}

.menu-socialmedia::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 100px;
  border-top: 1px solid rgb(116, 115, 115);
  left: 50%;
  transform: translateX(-50%);
}

.menu-bottom-text {
  position: absolute;
  width: 40%;
  bottom: 4%;
  align-self: center;
  flex-grow: 1;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 200;
  color: #ffffff;
  text-align: center;
  opacity: 80%;
}

.scroll-to-top {
  position: fixed;
  bottom: 4%;
  right: 4%;
  width: 55px;
  height: auto;
  padding: 1%;
  background-color: #141414;
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
}

.logo {
  height: 35px;
  width: auto;
  margin: 0 auto;
}

@media (max-height: 735px) {
  .sidenav .closebtn {
    top: 10px;
    font-size: 30px;
  }

  .menu-items {
    padding: 6%;
  }

  .menu-socialmedia1 {
    bottom: 12%;
  }

}

@media (max-width: 1000px) {
  .menu-socialmedia {
    margin-top: 60%;
  }

  .menu-socialmedia1 {
    margin-top: 60%;
  }

  .menu-icon {
    position: absolute;
    top: 25%;
    left: 4%;
    width: 30px;
  }

  .scroll-to-top {
    width: 45px;
    height: auto;
    padding: 1%;
  }

}

@media (max-width: 500px) {
  .sidenav {
    padding-top: 10px;
  }

  .sidenav a {
    font-size: 18px;
  }

  .closebtn {
    font-size: 30px;
  }

  .scroll-to-top {
    width: 35px;
    height: auto;
    padding: 1%;
  }
}

@media (max-width: 350px) {
  .menu-icon {
    top: 20%;
    left: 4%;
  }
}